










































































import Store from '@/models/Store.model';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseMixin from '@/misc/BaseMixin.mixins';

@Component({
  components: {
    MapComponent: () => import(
      /* webpackChunkName: "MapComponent" */
      '@/components/Map.component.vue'
    )
  }
})
export default class StorePropertiesComponent extends mixins(BaseMixin) {
  @Prop({ required: true })
  public store!: Store;

  private basicProperties = [
    { title: 'STORE.TAX_ID', value: 'taxId', icon: 'fas fa-id-card', type: 'text' },
    { title: 'STORE.WEB_PAGE', value: 'webPage', icon: 'fas fa-home', type: 'link' },
    { title: 'STORE.IMPRINT', value: 'imprint', icon: 'fas fa-book-open', type: 'link' },
  ];
}
